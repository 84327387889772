export const environment = {
  apiParq: 'https://api.parqco.com/api',
  clients: {
    operator: 'https://beta.parqco.com',
  },
  email: '@users.parqco.com',
  kushkiMerchantId: '21926e14fcbb46a89bc123945da8a77a',
  production: true,
  parq: {
    driverId: 9100,
  },
  microliths: {
    entities: 'https://api.parqco.com/api/entities/v2',
    management: 'https://api.parqco.com/api/management/v2',
    payments: 'https://api.parqco.com/api/payments/v2',
    services: 'https://api.parqco.com/api/services/v2',
    users: 'https://api.parqco.com/api/users/v2',
  },
};
